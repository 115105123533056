webApp.controller('indexController', ['$scope', '$anchorScroll', '$window', '$timeout', '$interval', '$sce',
    'indexAppHttp', 'scrollFocus', 'SweetAlert', 'modalMethods', '$cookieStore',
    function ($scope, $anchorScroll, $window, $timeout, $interval, $sce, indexAppHttp, scrollFocus, SweetAlert,
              modalMethods,$cookieStore) {

        var indexVm = this;
        indexVm.intentosHttp = 3;
        indexVm.contadorCargarImagenesCarrusel = 0;
        indexVm.contadorCargarEmpresaInfo = 0;
        indexVm.contadorImagenesCarrusel = 0;
        indexVm.contadorCargarServicios = 0;
        indexVm.contadorCargarBanner = 0;
        indexVm.listaImagenesCarrusel = [];
        indexVm.listaServicios = [];
        indexVm.listaServiciosPaginacion = {
            'last_page': 1
        };

        var imagenesCarruselTrancicion = function () {

            if (indexVm.contadorImagenesCarrusel >= indexVm.listaImagenesCarrusel.length) {

                indexVm.contadorImagenesCarrusel = 0;

            }

            if (indexVm.listaImagenesCarrusel.length) {
                if (indexVm.listaImagenesCarrusel[indexVm.contadorImagenesCarrusel]) {

                    angular.forEach(indexVm.listaImagenesCarrusel, function (value, key) {
                        indexVm.listaImagenesCarrusel[key].activo = false;
                    });

                    var imagen = indexVm.listaImagenesCarrusel[indexVm.contadorImagenesCarrusel].imagen;

                    indexVm.imageneCarruselClass = {
                        'transition': 'all 3s linear',
                        '-webkit-transition': 'all 3s linear',
                        '-moz-transition': 'all 3s linear',
                        '-o-transition': 'all 3s linear',
                        '-ms-transition': 'all 3s linear',
                        'background-image': 'url(' + imagen + ')'
                    };

                    indexVm.tituloCarrusel = indexVm.listaImagenesCarrusel[indexVm.contadorImagenesCarrusel].titulo;

                    indexVm.descripcionCarrusel = indexVm.listaImagenesCarrusel[indexVm.contadorImagenesCarrusel].descripcion;

                    indexVm.listaImagenesCarrusel[indexVm.contadorImagenesCarrusel].activo = true;

                    indexVm.contadorImagenesCarrusel = indexVm.contadorImagenesCarrusel + 1;

                }

            }

            $timeout(imagenesCarruselTrancicion, 7000);

        };


        indexVm.trustAsHtml = function (html) {
            return $sce.trustAsHtml(html);
        };

        indexVm.backgroundStyle = function (img) {

            if (img) {
                return {
                    'background-image': 'url(' + img + ')'
                };
            } else {
                return {};
            }

        };

        indexVm.imagenesCarrusel = function () {

            var idioma = $cookieStore.get('idioma') ? $cookieStore.get('idioma') : 'es';

            var parametros  = '?lang=' + idioma;

            indexAppHttp.getlistarImagenesCarrusel(parametros).then(function (resultado) {

                indexVm.listaImagenesCarrusel = resultado.data.carrusel;

                indexVm.imageneCarruselClass = {
                    'transition': 'background-image 5s linear',
                    '-webkit-transition': 'background-image 5s linear',
                    '-moz-transition': 'background-image 5s linear',
                    '-o-transition': 'background-image 5s linear',
                    '-ms-transition': 'background-image 5s linear',
                    'background-image': 'url(' + resultado.data.carrusel[indexVm.contadorImagenesCarrusel].imagen + ')'
                };

                indexVm.tituloCarrusel = resultado.data.carrusel[indexVm.contadorImagenesCarrusel].titulo;

                indexVm.descripcionCarrusel = indexVm.listaImagenesCarrusel[indexVm.contadorImagenesCarrusel].descripcion;

                indexVm.listaImagenesCarrusel[indexVm.contadorImagenesCarrusel].activo = true;

                indexVm.contadorImagenesCarrusel = indexVm.contadorImagenesCarrusel + 1;

                $timeout(imagenesCarruselTrancicion, 7000);

            }).catch(function (resultado) {
                if (indexVm.contadorCargarImagenesCarrusel < indexVm.intentosHttp) {
                    indexVm.contadorCargarImagenesCarrusel++;
                    indexVm.imagenesCarrusel();
                }
            });

        };

        indexVm.empresaInfo = function(){

            var idioma = $cookieStore.get('idioma') ? $cookieStore.get('idioma') : 'es';

            var parametros  = '?limite=4&lang=' + idioma;

            indexAppHttp.getListarEmpresaInfo(parametros).then(function (resultado) {

                indexVm.listaInfoEmpresa = resultado.data.empresa;
                indexVm.listaInfoEmpresa.splice(0,1);
                indexVm.InfoEmpresaDescripcion = indexVm.listaInfoEmpresa[0].descripcion;
                indexVm.listaInfoEmpresa[0].activo = true;

            }).catch(function (resultado) {
                if (indexVm.contadorCargarEmpresaInfo < indexVm.intentosHttp) {
                    indexVm.contadorCargarEmpresaInfo++;
                    indexVm.empresaInfo();
                }
            });


        };

        indexVm.empresaInfoSelect = function(index){

            var close = false;

            angular.forEach(indexVm.listaInfoEmpresa, function(value, key) {
               if(index == key && value.activo == true){
                   close = true;
               }
                indexVm.listaInfoEmpresa[key].activo = false;
            });

            if(!close){
                indexVm.InfoEmpresaDescripcion = indexVm.listaInfoEmpresa[index].descripcion;
                indexVm.listaInfoEmpresa[index].activo = true;
            }



        };

        indexVm.listarServicios = function (pagina) {

            indexVm.listaServiciosPaginacion.last_page = (indexVm.listaServiciosPaginacion.last_page ?
                indexVm.listaServiciosPaginacion.last_page : 1);

            if (pagina <= indexVm.listaServiciosPaginacion.last_page) {
                if (pagina >= 1) {

                    var idioma = $cookieStore.get('idioma') ? $cookieStore.get('idioma') : 'es';

                    var parametros = '?page=' + pagina + '&limite=5&lang=' + idioma;

                    indexVm.paginaActual = pagina;

                    indexAppHttp.getListarServicios(parametros).then(function (resultado) {

                        indexVm.listaServicios = resultado.data.servicios;
                        indexVm.listaServiciosPaginacion = resultado.data.paginate;
                        indexVm.servicioDescripcion = indexVm.listaServicios[0].descripcion;
                        indexVm.listaServicios[0].activo = true;

                    }).catch(function (resultado) {

                        if (indexVm.contadorCargarServicios < indexVm.intentosHttp) {
                            indexVm.contadorCargarServicios++;
                            indexVm.listarServicios(pagina);
                        }

                    });

                }
            }

        };
        
        indexVm.servicioSelect = function(index){

            var close = false;

            angular.forEach(indexVm.listaServicios, function(value, key) {
                if(index == key && value.activo == true){
                    close = true;
                }
                indexVm.listaServicios[key].activo = false;
            });

            if(!close){
                indexVm.servicioDescripcion = indexVm.listaServicios[index].descripcion;
                indexVm.listaServicios[index].activo = true;
            }

        };

        indexVm.bannerInfo = function () {

            var idioma = $cookieStore.get('idioma') ? $cookieStore.get('idioma') : 'es';

            var parametros  = '?lang=' + idioma;

            indexAppHttp.getlistarBanner(parametros).then(function (resultado) {

                indexVm.infoBanner = resultado.data.banner;

            }).catch(function (resultado) {
                if (indexVm.contadorCargarBanner < indexVm.intentosHttp) {
                    indexVm.contadorCargarBanner++;
                    indexVm.bannerInfo();
                }
            });

        };



        indexVm.imagenesCarrusel();
        indexVm.empresaInfo();
        indexVm.listarServicios(1);
        indexVm.bannerInfo();

    }]);