indexAppHttp = angular.module('webApp');

var url_api = "/api/";

indexAppHttp.factory('indexAppHttp', ['$http', function ($http) {

    return {

        getlistarImagenesCarrusel: function getlistarImagenesCarrusel(parametros) {
            return $http.get(url_api +  'carrusel/aleatorios' + parametros);
        },
        getListarEmpresaInfo: function getListarEmpresaInfo(parametros) {
            return $http.get(url_api +  'empresa/listar' + parametros);
        },
        getListarServicios: function getListarServicios(parametros) {
            return $http.get(url_api +  'servicios/listar' + parametros);
        },
        getlistarBanner: function getlistarBanner(parametros) {
            return $http.get(url_api +  'banner/aleatorios' + parametros);
        }


    }

}]);